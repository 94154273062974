<template>
  <div>
    <Card>
      <template #header>Ürün Listesi</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-dropdown
              id="dropdown-action"
              variant="outline-light"
              text="Toplu İşlemler"
              class="mr-2"
            >
              <b-dropdown-item @click="actions('allInActive')"
                >Seçilenleri Pasife Çek</b-dropdown-item
              >
              <b-dropdown-item @click="actions('allActive')"
                >Seçilenleri Aktife Çek</b-dropdown-item
              >
              <b-dropdown-item @click="actions('allRemove')"
                >Seçilenleri Kaldır</b-dropdown-item
              >
            </b-dropdown>
          </b-form-group>

          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="openInsertUpdate('')"
              >KAYIT EKLE</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Ürün Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
      @selectedItemIdsChanged="selectedItemIdsChanged"
      @openCreateModal="openInsertUpdate('')"
    >
      <template v-slot:item.isActive="{ item }">
        <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
      </template>
      <template v-slot:item.action="{ item }"> 
        <b-dropdown size="sm" no-caret dropleft variant="muted">
          <template v-slot:button-content>
            <i class="ki ki-bold-more-ver text-dark"></i>
          </template>
          <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
          <b-dropdown-item @click="openInsertUpdate(item)"
            >Düzenle</b-dropdown-item
          >
          <b-dropdown-item @click="openDeleteHandler(item)"
            >Sil</b-dropdown-item
          >
        </b-dropdown> 
      </template>
    </List>
    <!-- Create and Actions Popups -->
    <InsertUpdateModal
      :title="selectedItem == '' ? 'Ürün Ekle' : 'Ürün Düzenle'"
      :show="showInsertUpdate"
      @closeDetail="closeInsertUpdate()"
      @confirm="confirmModal()"
      :resetForm="resetForm"
    >
      <InsertUpdateOperations
        :selectedItem="selectedItem"
        :confirmModal="confirmCreate"
        @resetInsertUpdateForm="resetInsertUpdateForm"
      />
    </InsertUpdateModal>

    <DetailModal
      title="Ürün Detay"
      :show="showDetail"
      @openInsertUpdate="openInsertUpdate(selectedItem)"
      @closeDetail="closeDetail"
    >
      <DetailView :selectedItem="selectedItem" />
    </DetailModal>

    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'systemUsers',
  data() {
    return {
      search: '',
      filters: '',
      showInsertUpdate: false,
      showDetail: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: '', value: 'id', checkboxField: true, size: '50px' },
        { text: 'Ürün Tipi', value: 'productType', size: '200px' },
        { text: 'Ürün Adı', value: 'name' },
        { text: 'Fiyat', value: 'price' },
        { text: 'Durum', value: 'isActive' },
        { text: '', value: 'action', size: '60px' },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: '',
      deleteOperationUrl: '',
      deleteIds: '',
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Ürün Listesi', route: '/urunler' },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `product?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.success == true && res.count > 0) {
            this.items = res.data;
            this.totalItems = res.count;

            this.pagination = {
              current_page: res.page,
              total_pages: res.pageCount,
              total_items: res.count,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    confirmModal() {
      this.confirmCreate = true;
      setTimeout(() => {
        this.confirmCreate = false;
      }, 200);
    },
    resetInsertUpdateForm(closeInsertUpdate = false) {
      if (closeInsertUpdate) {
        this.closeInsertUpdate();
        this.getList();
      }

      this.resetForm = true;
      setTimeout(() => {
        this.resetForm = false;
      }, 200);
    },
    closeInsertUpdate() {
      setTimeout(() => {
        this.selectedItem = '';
      }, 100);
      this.showInsertUpdate = false;
    },
    openInsertUpdate(item) {
      this.selectedItem = item;
      this.showInsertUpdate = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    openDetail(item) {
      this.selectedItem = item;
      this.showDetail = true;
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `product`;
      this.deleteIds = item.id;
      this.$bvModal.show('modal-deleteOperation');
    },
    selectedItemIdsChanged(payload) {
      this.listSelectedItemIds = payload;
    },
    actions(payload) {
      if (
        this.listSelectedItemIds === [] ||
        this.listSelectedItemIds.length == 0
      ) {
        this.$generateNotification(
          this,
          'warning',
          'İşlem yapılacak seçili kayıt bulunamadı!'
        );
      } else {
        if (payload == 'allRemove') {
          this.deleteOperationUrl = `product`;
          this.deleteIds = this.listSelectedItemIds;
          this.$bvModal.show('modal-deleteOperation');
        } else if (payload == 'allInActive' || payload == 'allActive') {
          this.loading = true;
          this.$dbFunctions
            .update(`product/update-status`, {
              ids: this.listSelectedItemIds,
              isActive: payload == 'allActive',
            })
            .then((res) => {
              this.$dbFunctions.setResponse(this, res);
              this.getList();
            })
            .catch((err) => {
              this.$dbFunctions.setResponse(this, err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  components: {
    InsertUpdateOperations: () =>
      import('@/components/product/modals/InsertUpdateOperations'),
    DetailView: () => import('@/components/product/modals/DetailView'),
  },
};
</script>
